import { useEffect, useState } from "react";
import {
  getDemoFromID,
  getDemoFromLink,
  getIterationFromLink,
  getIterations
} from "../../services/demo";
import NamePage from "./components/NamePage";
import ChooseButtonsPage from "./components/ChooseButtonsPage";
import FinishPage from "./components/FinishPage";

interface OnboardingProps {
  iterationLink: string;
  demoLink: string;
  targetIDs: Map<string, string>;
  setTargetIDs: (targetIDs: Map<string, string>) => void;
}

const Onboarding = ({
  iterationLink,
  demoLink,
  targetIDs,
  setTargetIDs
}: OnboardingProps) => {
  const [demoID, setDemoID] = useState("");
  const [demoName, setDemoName] = useState("");
  const [figmaLink, setFigmaLink] = useState("");
  const [newIterationLink, setNewIterationsLink] = useState("");

  const [onboardingPage, setOnboardingPage] = useState(
    "name" || "choose button" || "finish"
  );

  // Get data from iteration link
  useEffect(() => {
    if (iterationLink) {
      getIterationFromLink(iterationLink).then((res) => {
        setDemoID(res.demo_id);
        setFigmaLink(res.figma_link);
      });
    }
  }, [iterationLink]);

  // Get data from demo link
  useEffect(() => {
    if (demoLink) {
      getDemoFromLink(demoLink).then((res) => {
        setDemoID(res.id);
        setDemoName(res.name);
      });
    }
  }, [demoLink]);

  useEffect(() => {
    if (demoID) {
      getDemoFromID(demoID).then((res) => {
        setDemoName(res.name);
      });
    }
  }, [demoID]);

  useEffect(() => {
    if (demoID) {
      getIterations(demoID).then((res) => {
        const lastIteration = res[res.length - 1];
        setFigmaLink(lastIteration.figma_link);
      });
    }
  }, [demoID]);

  const [buttons, setButtons] = useState(
    new Map<string, boolean>([
      ["Påmelding", false],
      ["Abonnement", false],
      ["Nyhetsbrev", false]
    ])
  );

  if (onboardingPage === "name") {
    return (
      <NamePage
        demoName={demoName}
        setDemoName={setDemoName}
        buttons={buttons}
        setButtons={setButtons}
        setOnboardingPage={setOnboardingPage}
        figmaLink={figmaLink}
        setFigmaLink={setFigmaLink}
      />
    );
  }
  if (onboardingPage === "choose button") {
    return (
      <ChooseButtonsPage
        figmaLink={figmaLink}
        targetIDs={targetIDs}
        setTargetIDs={setTargetIDs}
        setOnboardingPage={setOnboardingPage}
        demoID={demoID}
        setNewIterationsLink={setNewIterationsLink}
      />
    );
  }
  if (onboardingPage === "finish") {
    return <FinishPage iterationLink={newIterationLink} />;
  }
};

export default Onboarding;
