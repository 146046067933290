import styles from "./StatsCard.module.scss";

import PositiveArrow from "../svg/PositiveArrow";
import NegativeArrow from "../svg/NegativeArrow";
import PersonsLogo from "../svg/PersonsLogo";
import { useEffect } from "react";
import { useState } from "react";

interface StatsCardProps {
  iterationNumbers?: number[];
  iterationPercents?: number[];
  infoText?: string;
  isPercentCard?: boolean;
  addedAcqusition?: boolean;
}

const StatsCard = ({
  iterationNumbers,
  infoText,
  isPercentCard,
  iterationPercents,
  addedAcqusition = true
}: StatsCardProps) => {
  const [visibleIterationNumbers, setVisibleIterationNumbers] = useState([]);
  const [visibleIterationPercents, setVisibleIterationPercents] = useState([]);

  useEffect(() => {
    if (iterationNumbers) {
      setVisibleIterationNumbers(
        iterationNumbers.length > 5
          ? iterationNumbers.slice(
              iterationNumbers.length - 5,
              iterationNumbers.length
            )
          : iterationNumbers
      );
    }

    if (iterationPercents) {
      setVisibleIterationPercents(
        iterationPercents.length > 5
          ? iterationPercents.slice(
              iterationPercents.length - 5,
              iterationPercents.length
            )
          : iterationPercents
      );
    }
  }, [iterationPercents, iterationNumbers]);

  if (!addedAcqusition) {
    return (
      <div className={styles.card}>
        <div className={styles.noAcquisitionContainer}>
          <div>
            <p className={styles.noAcquisitionTitle}>Konverteringsrate</p>
            <p className={styles.noAcquisitionText}>
              Hvor mange personer som har sett innlegget
            </p>
          </div>
          <button className={styles.option}>+ Legg til</button>
        </div>
      </div>
    );
  }

  return (
    <div
      className={
        progressionBorder(visibleIterationNumbers) + " " + styles.card
      }>
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <div className={styles.numberInfoTextContainer}>
            <div className={styles.numberContainer}>
              {isPercentCard
                ? `${
                    visibleIterationPercents[
                      visibleIterationPercents.length - 1
                    ]
                  }%`
                : visibleIterationNumbers[visibleIterationNumbers.length - 1]}
            </div>
            <p className={styles.infoText}>{infoText}</p>
          </div>
          <div>
            {isPercentCard ? (
              <div className={styles.personsContainer}>
                <div style={{ paddingRight: "0.2rem" }}>
                  {iterationNumbers[iterationNumbers.length - 1]}
                </div>
                <PersonsLogo />
              </div>
            ) : (
              <div />
            )}
            <div
              className={
                styles.percentContainer +
                " " +
                progressionPercent(visibleIterationNumbers)
              }>
              <div className={styles.arrowContainer}>
                {progressionArrows(visibleIterationNumbers)}
              </div>
              <div className={styles.percentNumberContainer}>
                {visibleIterationNumbers.length > 1
                  ? `${Math.abs(
                      Math.round(
                        ((visibleIterationNumbers[
                          visibleIterationNumbers.length - 1
                        ] -
                          visibleIterationNumbers[
                            visibleIterationNumbers.length - 2
                          ]) /
                          visibleIterationNumbers[
                            visibleIterationNumbers.length - 2
                          ]) *
                          100
                      )
                    ).toString()}%`
                  : "- %"}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.barPlotContainer}>
          {visibleIterationNumbers.map((bar, v) => {
            const lastElement = v === visibleIterationNumbers.length - 1;
            return (
              <div className={styles.barContainer} key={v}>
                <div
                  className={
                    lastElement
                      ? styles.bar +
                        " " +
                        progressionBars(visibleIterationNumbers)
                      : styles.bar + " " + styles.neutralBar
                  }
                  style={{
                    height: `${
                      (bar / Math.max(...visibleIterationNumbers)) * 100 * 0.8
                    }%`
                  }}></div>
                <div className={styles.vContainer}>{`V${
                  v +
                  1 +
                  iterationNumbers.length -
                  visibleIterationNumbers.length
                }`}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default StatsCard;

const progressionBars = (iterationNumbers: number[]) => {
  if (iterationNumbers.length > 1) {
    if (
      iterationNumbers[iterationNumbers.length - 1] >
      iterationNumbers[iterationNumbers.length - 2]
    ) {
      return styles.positiveBar;
    }

    if (
      iterationNumbers[iterationNumbers.length - 1] <
      iterationNumbers[iterationNumbers.length - 2]
    ) {
      return styles.negativeBar;
    }

    if (
      iterationNumbers[iterationNumbers.length - 1] ===
      iterationNumbers[iterationNumbers.length - 2]
    ) {
      return styles.neutralBar;
    }
  } else {
    return styles.neutralBar;
  }
};

const progressionBorder = (iterationNumbers: number[]) => {
  if (iterationNumbers.length > 1) {
    if (
      iterationNumbers[iterationNumbers.length - 1] >
      iterationNumbers[iterationNumbers.length - 2]
    ) {
      return styles.positiveBorder;
    }

    if (
      iterationNumbers[iterationNumbers.length - 1] <
      iterationNumbers[iterationNumbers.length - 2]
    ) {
      return styles.negativeBorder;
    }

    if (
      iterationNumbers[iterationNumbers.length - 1] ===
      iterationNumbers[iterationNumbers.length - 2]
    ) {
      return styles.neutralBorder;
    }
  } else {
    return styles.neutralBorder;
  }
};

const progressionPercent = (iterationNumbers: number[]) => {
  if (iterationNumbers.length > 1) {
    if (
      iterationNumbers[iterationNumbers.length - 1] >
      iterationNumbers[iterationNumbers.length - 2]
    ) {
      return styles.positivePercent;
    }

    if (
      iterationNumbers[iterationNumbers.length - 1] <
      iterationNumbers[iterationNumbers.length - 2]
    ) {
      return styles.negativePercent;
    }

    if (
      iterationNumbers[iterationNumbers.length - 1] ===
      iterationNumbers[iterationNumbers.length - 2]
    ) {
      return styles.neutralPercent;
    }
  } else {
    return styles.neutralPercent;
  }
};

const progressionArrows = (iterationNumbers: number[]) => {
  if (
    iterationNumbers[iterationNumbers.length - 1] >
    iterationNumbers[iterationNumbers.length - 2]
  ) {
    return <PositiveArrow />;
  }

  if (
    iterationNumbers[iterationNumbers.length - 1] <
    iterationNumbers[iterationNumbers.length - 2]
  ) {
    return <NegativeArrow />;
  }
};
