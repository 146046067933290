import styles from "./Landing.module.scss";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import NavBar from "../../components/NavBar";
import { useNavigate } from "react-router-dom";
import { addDemo } from "../../services/demo";

interface LandingProps {
  projectName: string;
  setProjectName: (projectName: string) => void;
}

const Info = () => {
  const tags = [
    "Bevis betalingsvilje",
    "Se bruksmønster",
    "Kjør effektive demoer"
  ];

  return (
    <div className={styles.info}>
      <h1 className={styles.title}>Produktinnsikt fra skisser.</h1>
      <div className={styles.tagContainer}>
        {tags.map((tag, index) => {
          const lastElement = index === tags.length - 1;
          return (
            <p key={index} className={styles.tag}>
              <span
                className={`${styles.tag} ${
                  !lastElement && styles.marginRight
                }`}>
                {tag}
              </span>
              <span className={styles.tagSeperator}>{!lastElement && "·"}</span>
            </p>
          );
        })}
      </div>
    </div>
  );
};

const Landing = ({ projectName, setProjectName }: LandingProps) => {
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <NavBar />
      <div className={styles.container}>
        <Info />
        <div className={styles.inputContainer}>
          <TextInput
            label="Navn på prosjekt "
            placeholder="Prosjektnavn"
            onChange={(e) => setProjectName(e.target.value)}
          />
          <Button
            text="Sett i gang"
            onClick={async () => {
              const demo = await addDemo({
                name: projectName
              });
              const demo_link: string = demo.demo_link;
              navigate(`/onboarding/?demoLink=${demo_link}`);
            }}
            disabled={projectName == ""}
          />
        </div>
      </div>
    </div>
  );
};

export default Landing;
