import { backendURL } from "./url";

export const addClick = async (targetID: string, sessionID: string) => {
  try {
    const response = await fetch(
      backendURL + `v1/sessions/${sessionID}/clicks`,
      {
        method: "POST",
        body: JSON.stringify({
          target_id: targetID,
          session_id: sessionID
        })
      }
    );
    const data = response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getClicks = async (iterationID: string[]) => {
  try {
    const response = await fetch(
      backendURL + `v1/iterations/${iterationID}/clicks?take=999999`,
      {
        method: "GET"
      }
    );
    const data = response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};
