import { Routes, Route, useLocation } from "react-router-dom";
import Demo from "./routes/Demo";
import Landing from "./routes/Landing";
import Stats from "./routes/Stats";
import NotFound from "./routes/NotFound";

import { useState } from "react";
import * as React from "react";
import Debug from "./routes/Debug";
import Onboarding from "./routes/Onboarding/Onboarding";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function App() {
  const [projectName, setProjectName] = useState("");
  const [primaryActions, setPrimaryActions] = useState([]);
  const [targetIDs, setTargetIDs] = useState(new Map<string, string>());

  const query = useQuery();

  return (
    <Routes>
      <Route
        path=""
        element={
          <Landing projectName={projectName} setProjectName={setProjectName} />
        }
      />
      <Route
        path="stats/*"
        element={
          <Stats
            iterationLink={query.get("iterationLink")}
            urlDemoLink={query.get("demoLink")}
          />
        }
      />
      <Route
        path="demo/*"
        element={<Demo iterationLink={query.get("iterationLink")} />}
      />
      <Route
        path="/new-iteration/debug/*"
        element={
          <Debug
            iterationLink={query.get("iterationLink")}
            primaryActions={primaryActions}
            setPrimaryActions={setPrimaryActions}
          />
        }
      />
      <Route
        path="onboarding/*"
        element={
          <Onboarding
            iterationLink={query.get("iterationLink")}
            demoLink={query.get("demoLink")}
            targetIDs={targetIDs}
            setTargetIDs={setTargetIDs}
          />
        }
      />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
}
