import { IDemo, IIteration } from "../types/types";
import { backendURL } from "./url";

export const getDemos = async () => {
  try {
    const response = await fetch(backendURL + "v1/demos", {
      method: "GET"
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const addDemo = async (demo: IDemo) => {
  try {
    const response = await fetch(backendURL + "v1/demos", {
      method: "POST",
      body: JSON.stringify(demo)
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getDemoFromLink = async (demoLink: string) => {
  try {
    const response = await fetch(
      backendURL + `v1/demos/demo-link/${demoLink}`,
      {
        method: "GET"
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getDemoFromID = async (demoID: string) => {
  try {
    const response = await fetch(backendURL + `v1/demos/${demoID}`, {
      method: "GET"
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getIterations = async (ID: string) => {
  try {
    const response = await fetch(backendURL + `v1/demos/${ID}/iterations`, {
      method: "GET"
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getIterationFromLink = async (iterationLink: string) => {
  try {
    const response = await fetch(
      backendURL + `v1/iteration/iteration-link/${iterationLink}`,
      {
        method: "GET"
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const addIteration = async (iteration: IIteration) => {
  try {
    const response = await fetch(
      backendURL + `v1/demos/${iteration.demo_id}/iterations`,
      {
        method: "POST",
        body: JSON.stringify(iteration)
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const addSession = async (iterationID: string) => {
  try {
    const response = await fetch(
      backendURL + `v1/iterations/${iterationID}/sessions`,
      {
        method: "POST",
        body: JSON.stringify({})
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getSessions = async (iterationID: string) => {
  try {
    const response = await fetch(
      backendURL + `v1/iterations/${iterationID}/sessions`,
      {
        method: "GET"
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const addPrimaryActions = async (
  iterationID: string,
  primaryActions: string[]
) => {
  try {
    const response = await fetch(
      backendURL + `v1/iterations/${iterationID}/primary-clicks`,
      {
        method: "POST",
        body: JSON.stringify({ primary_actions: primaryActions })
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};
