import { useEffect, useState } from "react";
import {
  getDemoFromID,
  getDemoFromLink,
  getDemos,
  getIterationFromLink
} from "../../services/demo";
import styles from "./Stats.module.scss";

import NavBar from "../../components/NavBar";
import StatsCard from "./components/StatsCard";
import LineArrow from "./svg/LineArrow";
import SideNav from "../../components/SideNav";
import ButtonOverview from "./components/ButtonOverview";
import GraphComponent from "./components/GraphComponent";
import { getStats } from "../../services/stats";
import { Session } from "../../types/types";
import LiveCounter from "./components/LiveCounter";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";

interface StatsProps {
  iterationLink?: string;
  urlDemoLink?: string;
}

const Stats = ({ iterationLink, urlDemoLink }: StatsProps) => {
  const [demoID, setDemoID] = useState("");
  const [demoLink, setDemoLink] = useState("");
  const [demoName, setDemoName] = useState("");
  const [demos, setDemos] = useState([]);
  const [clicks, setClicks] = useState([]);
  const [sessionsAndClicksList, setSessionsAndClicksList] = useState([]);
  const [numberOfClicksList, setNumberOfClicksList] = useState([]);
  const [numberOfSessionsList, setNumberOfSessionsList] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [iterations, setIterations] = useState([]);
  const [primaryActionIDs, setPrimaryActionIDs] = useState([]);

  const navigate = useNavigate();

  // Get data from iteration link
  useEffect(() => {
    if (iterationLink) {
      getIterationFromLink(iterationLink).then((res) => {
        setDemoID(res.demo_id);
        setPrimaryActionIDs(res.primary_action_ids);
      });
    }
  }, [iterationLink]);

  // Get data from demo link
  useEffect(() => {
    if (urlDemoLink) {
      getDemoFromLink(urlDemoLink).then((res) => {
        setDemoID(res.id);
        setDemoName(res.name);
      });
    }
  }, [urlDemoLink]);

  // Get stats data for demo
  useEffect(() => {
    if (demoID) {
      getDemoFromID(demoID).then((res) => {
        setDemoName(res.name);
        setDemoLink(res.demo_link);
      });
    }
  }, [demoID]);

  // Get all demos
  useEffect(() => {
    getDemos().then((res) => {
      setDemos(res);
      setDemoName(res[res.length - 1].name);
    });
  }, []);

  // Get stats for current demo
  useEffect(() => {
    if (demoID) {
      getStats(demoID).then((res) => {
        setIterations(res.iterations);
      });
    }
  }, [demoID]);

  // Get sessions from iterations
  useEffect(() => {
    if (iterations) {
      const tempList: any = [];
      iterations.forEach((iteration) => {
        tempList.push(iteration.sessions);
      });
      setSessionsAndClicksList(tempList);
    }
  }, [iterations]);

  // Set number of sessions
  useEffect(() => {
    if (sessionsAndClicksList && sessionsAndClicksList.length > 0) {
      const numberOfSessionsTempList: any = [];
      const numberOfClicksTempList: any = [];
      sessionsAndClicksList.forEach((iteration) => {
        numberOfSessionsTempList.push(iteration ? iteration.length : 0);
        let clicksTemp = 0;
        if (iteration) {
          iteration.forEach((session: any) => {
            clicksTemp = session.clicks
              ? clicksTemp + session.clicks.length
              : clicksTemp;
          });
        }
        numberOfClicksTempList.push(clicksTemp);
      });
      setNumberOfSessionsList(numberOfSessionsTempList);
      setNumberOfClicksList(numberOfClicksTempList);
    }
  }, [sessionsAndClicksList]);

  useEffect(() => {
    const lastIteration =
      sessionsAndClicksList[sessionsAndClicksList.length - 1];
    if (lastIteration) {
      let clicksTempList: any[] = [];
      const sessionsTempList: Session[] = [];
      lastIteration.forEach((session: any) => {
        sessionsTempList.push({
          id: session.session.id,
          iteration_id: session.session.iteration_id,
          started_at: session.session.started_at,
          stopped_at: session.session.stopped_at
        });
        clicksTempList = session.clicks
          ? [...clicksTempList, ...session.clicks]
          : [...clicksTempList, 0];
      });
      setClicks(clicksTempList);
      setSessions(sessionsTempList);
    }
  }, [sessionsAndClicksList]);

  if (demos) {
    return (
      <div className={styles.wrapper}>
        <NavBar />
        <div className={styles.content}>
          <SideNav demos={demos} demoLink={demoLink} />
          <div className={styles.container}>
            <div className={styles.titleContainer}>
              <h1 className={styles.title}>{demoName}</h1>
              <div className={styles.introText}>
                Her er en oversikt over hvordan det går med fremgangen til
                prosjektet ditt.
              </div>
            </div>
            <div className={styles.keyNumbersContainer}>
              <div className={styles.titleContainer}>
                <h2 className={styles.sectionHeader}>Nøkkeltall</h2>
              </div>
              <div className={styles.statsCardContainer}>
                <StatsCard addedAcqusition={false} />
                <div className={styles.arrowContainer}>
                  <LineArrow />
                </div>
                <StatsCard
                  iterationNumbers={numberOfSessionsList}
                  infoText="Unike besøk"
                  isPercentCard={false}
                />
                <div className={styles.arrowContainer}>
                  <LineArrow />
                </div>
                <StatsCard
                  iterationNumbers={numberOfClicksList}
                  infoText="Klikk på demoen"
                  isPercentCard={false}
                />
              </div>
            </div>
            <Button
              text="+ Legg til ny versjon"
              onClick={() => {
                navigate(`/onboarding/?demoLink=${demoLink}`);
              }}
            />
            <div className={styles.iterationsContainer}>
              <div className={styles.iterationsHeaderContainer}>
                <h2 className={styles.sectionHeader}>Statistikk for versjon</h2>
                <LiveCounter
                  iterationID={
                    iterations.length > 0 && iterations[0].iteration.id
                  }
                />
              </div>
              <div className={styles.iterationsStatsContainer}>
                <div className={styles.topTargetIDsContainer}>
                  <ButtonOverview
                    clicks={clicks}
                    numberOfsessions={
                      numberOfSessionsList[numberOfSessionsList.length - 1]
                    }
                    primaryActionIDs={primaryActionIDs}></ButtonOverview>
                </div>
                <div className={styles.graphContainer}>
                  <GraphComponent sessions={sessions} iterations={iterations} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.wrapper}>
        <NavBar />
        <div className={styles.content}>
          <SideNav demos={demos} demoLink={demoLink} />
          <div className={styles.container}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>
                Du har ingen demoer. Legg til en demo for å starte.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Stats;
