const NegativeArrow = () => {
  return (
    <svg
      width="9"
      height="11"
      viewBox="0 0 9 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.60547 1.00003L4.60547 9.73242"
        stroke="#CF5766"
        strokeWidth="1.45029"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 6.09392L4.6036 9.73242L8.20721 6.09392"
        stroke="#CF5766"
        strokeWidth="1.45029"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NegativeArrow;
