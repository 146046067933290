import { backendURL } from "./url";

export const getStats = async (demoID: string) => {
  try {
    const response = await fetch(backendURL + `v1/demos/${demoID}/stats`, {
      method: "GET"
    });
    const data = response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};
