import styles from "./Button.module.scss";

interface ButtonProps {
  onClick?: () => void;
  text: string;
  disabled?: boolean;
  className?: any;
}

const Button = ({ onClick, text, disabled, className }: ButtonProps) => {
  return (
    <button
      className={`${styles.button} ${className}`}
      onClick={onClick}
      disabled={disabled}>
      {text}
    </button>
  );
};

export default Button;
