import { useEffect, useState } from "react";
import { getActiveSessions } from "../../../services/sessions";
import styles from "./LiveCounter.module.scss";

interface LiveCounterProps {
  iterationID: string;
}

const LiveCounter = ({ iterationID }: LiveCounterProps) => {
  const [activeSessions, setActiveSessions] = useState(0);

  useEffect(() => {
    getActiveSessions(iterationID).then((res) => {
      setActiveSessions(res);
    });
  }, [iterationID]);

  return (
    <div className={styles.container}>
      <div className={styles.liveContainer}>
        <p className={styles.liveText}>Demo er live</p>
        <span className={styles.liveDot}></span>
      </div>
      <div className={styles.statsContainer}>
        <span className={styles.number}>{activeSessions}</span>
        <span className={styles.text}>aktive brukere nå</span>
      </div>
    </div>
  );
};

export default LiveCounter;
