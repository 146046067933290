import styles from "./SideNav.module.scss";

import { useNavigate } from "react-router-dom";
import { Demo } from "../../types/types";

interface SideNavProps {
  demos: Demo[];
  demoLink: string;
}

const SideNav = ({ demos, demoLink }: SideNavProps) => {
  const navigate = useNavigate();

  if (demos) {
    return (
      <div>
        <div className={styles.sideNav}>
          {demos.map((demo, index) => {
            return demoLink === demo.demo_link ? (
              <div
                className={styles.optionCurrent}
                key={index}
                onClick={() => navigate(`/stats/?demoLink=${demo.demo_link}`)}>
                {demo.name}
              </div>
            ) : (
              <div
                className={styles.option}
                key={index}
                onClick={() => navigate(`/stats/?demoLink=${demo.demo_link}`)}>
                {demo.name}
              </div>
            );
          })}
          <div className={styles.option} onClick={() => navigate("/")}>
            + Legg til ny demo
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className={styles.sideNav}>
          <div className={styles.option} onClick={() => navigate("/")}>
            + Legg til ny demo
          </div>
        </div>
      </div>
    );
  }
};

export default SideNav;
