const LineArrow = () => {
  return (
    <svg
      width="26"
      height="10"
      viewBox="0 0 26 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.3834 5.42816C25.6199 5.19169 25.6199 4.80831 25.3834 4.57184L21.5299 0.718375C21.2935 0.481907 20.9101 0.481907 20.6736 0.718375C20.4371 0.954843 20.4371 1.33823 20.6736 1.5747L24.0989 5L20.6736 8.4253C20.4371 8.66177 20.4371 9.04516 20.6736 9.28162C20.9101 9.51809 21.2935 9.51809 21.5299 9.28162L25.3834 5.42816ZM0 5.60551H24.9552V4.39449H0V5.60551Z"
        fill="#D0D5DD"
      />
    </svg>
  );
};

export default LineArrow;
