import styles from "./Debug.module.scss";

import NavBar from "../../components/NavBar";
import IFrame from "../Demo/IFrame";
import Button from "../../components/Button";

import { useState, useEffect } from "react";
import { getIterationFromLink } from "../../services/demo";
import { useNavigate } from "react-router-dom";

interface DebugProps {
  iterationLink: string;
  primaryActions?: any[];
  setPrimaryActions: (primaryActionList: []) => void;
}

const Debug = ({ iterationLink }: DebugProps) => {
  const [link, setLink] = useState("");
  const [targetID, setTargetID] = useState("");
  const [primaryActionList, setPrimaryActionList] = useState([]);
  const [initialLoad, setInitialLoad] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getIterationFromLink(iterationLink).then((res) => {
      setLink(res.figma_link);
    });

    function eventHandler(event: any) {
      if (event.origin !== "https://www.figma.com") return;
      if (event.data.data.targetNodeId)
        setTargetID(event.data.data.targetNodeId);

      if (event.data.type === "INITIAL_LOAD") {
        setInitialLoad(true);
      }
    }

    window.addEventListener("message", eventHandler);

    return () => {
      window.removeEventListener("message", eventHandler);
    };
  }, [link]);

  if (!link) {
    return <div style={{ color: "white" }}>Loading...</div>;
  }
  return (
    <div className={styles.wrapper}>
      <NavBar />
      <div className={styles.container}>
        <div className={styles.title}>Legg til primary actions</div>
        <div className={styles.infoText}>
          Her kan du legge til primary actions. En primary action er et
          knappetrykk eller en komponent du vil Iterate Demo skal ha et ekstra
          fokus på. I mange tilfeller er dette én eller flere knapper som viser
          kjøpsintensjon eller interesse. Legg til ideer i primary action-listen
          ved å klikke deg gjennom demoen din og trykke "Legg til komponent"
          etter du har klikket på ønsker primary action. Vær obs på at om
          kompoenten du trykker på skal være en knapp må Figma-skissen endre seg
          etter klikk.
        </div>
        <div className={styles.targetIDContainer}>
          <div className={styles.targetID}>
            {`ID til komponenten du trykket på sist: ${targetID}`}
          </div>
          <Button
            className={styles.button}
            text="Legg til komponent"
            onClick={() =>
              setPrimaryActionList((primaryActionList) => [
                ...primaryActionList,
                targetID
              ])
            }
          />
          <div className={styles.addedTargetsContainer}>
            {primaryActionList.join(", ")}
          </div>
          <Button
            text="Tilbake til oppsett"
            onClick={() => navigate("/onboarding")}
          />
        </div>
        <div>
          <div
            className={
              initialLoad
                ? styles.IFrameContainer
                : styles.IFrameContainerHidden
            }>
            <IFrame link={link}></IFrame>
          </div>
          <div
            className={
              initialLoad
                ? styles.IFrameContainerHidden
                : styles.IFrameContainer
            }
            style={{ color: "white", fontSize: "3rem" }}>
            Loading demo...
          </div>
        </div>
      </div>
    </div>
  );
};

export default Debug;
