import NavBar from "../../../components/NavBar";
import styles from "./ChooseButtonsPage.module.scss";

import LoadingAnimation from "../../../assets/images/demo-loading-screen.gif";

import One from "../svg/one";
import Two from "../svg/two";
import { useEffect, useState } from "react";
import IFrame from "../../Demo/IFrame";
import ContinueArrow from "../svg/continueArrow";
import { addIteration } from "../../../services/demo";

interface ChooseButtonsPageProps {
  figmaLink: string;
  targetIDs: Map<string, string>;
  setTargetIDs: (targetIDs: Map<string, string>) => void;
  setOnboardingPage: (onboardingPage: string) => void;
  demoID: string;
  setNewIterationsLink: (newIterationsLink: string) => void;
}

const ChooseButtonsPage = ({
  figmaLink,
  targetIDs,
  setTargetIDs,
  setOnboardingPage,
  demoID,
  setNewIterationsLink
}: ChooseButtonsPageProps) => {
  const [targetID, setTargetID] = useState("");
  const [initialLoad, setInitialLoad] = useState(false);
  const [targetIDName, setTargetIDName] = useState("");

  useEffect(() => {
    async function eventHandler(event: any) {
      if (event.origin !== "https://www.figma.com") return;

      // On initial load, create the session in backend and get the session ID
      if (event.data.type === "INITIAL_LOAD") {
        setInitialLoad(true);
      }

      if (event.data.type === "MOUSE_PRESS_OR_RELEASE") {
        setTargetID(event.data.data.targetNodeId);
      }
    }

    window.addEventListener("message", eventHandler);

    return () => {
      window.removeEventListener("message", eventHandler);
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <NavBar />
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <div className={styles.subtitle}>Velg ut knapper fra din skisse</div>
          <div className={styles.title}>
            Hvilke knapper ønsker du å følge med på?
          </div>
          <div className={styles.buttonContainer}>
            <div className={styles.point}>
              <div>
                <One />
              </div>
              <div className={styles.pointText}>
                Trykk rundt på skissen din for å velge knapper og å orientere
                deg rundt.
              </div>
            </div>
            <div className={styles.point}>
              <div>
                <Two />
              </div>
              <div className={styles.pointText}>
                Den siste knappen du har trykket på havner i boksen under. Klikk
                på "legg til knapp" for å legge til i listen over knapper du
                ønsker å tracke. Det kan være lurt å navngi knapper du ønsker å
                ha et spesielt ønske til.
              </div>
            </div>
            <div className={styles.tragetIDContainer}>{targetID}</div>
            <div className={styles.addTargetIDContainer}>
              <input
                className={styles.targetIDNameInput}
                placeholder="Navngi knappen"
                value={targetIDName}
                onChange={(e) => setTargetIDName(e.target.value)}
              />
              <div
                className={styles.addTargetIDButton}
                onClick={() =>
                  addTargetIDHandler(
                    targetIDName,
                    targetID,
                    targetIDs,
                    setTargetIDs,
                    setTargetIDName,
                    setTargetID
                  )
                }>
                + Legg til knapp
              </div>
            </div>
          </div>
          <div className={styles.continueButtonContainer}>
            <div
              className={styles.continueButton}
              onClick={() =>
                onClickHandler(
                  demoID,
                  figmaLink,
                  "",
                  targetIDs,
                  setNewIterationsLink,
                  setOnboardingPage
                )
              }>
              Ferdig <ContinueArrow />
            </div>
          </div>
        </div>
        <div
          className={
            initialLoad ? styles.IFrameContainer : styles.IFrameContainerHidden
          }>
          <IFrame link={figmaLink} />
        </div>
        <div
          className={
            initialLoad ? styles.IFrameContainerHidden : styles.IFrameContainer
          }>
          <img
            className={styles.loadingAnimation}
            src={LoadingAnimation}
            alt="En animasjon som viser at demoen din laster inn"
          />
        </div>
      </div>
    </div>
  );
};

export default ChooseButtonsPage;

const addTargetIDHandler = (
  targetIDName: string,
  targetID: string,
  targetIDs: Map<string, string>,
  setTargetIDs: (targetIDs: Map<string, string>) => void,
  setTargetIDName: (targetIDName: string) => void,
  setTargetID: (targetID: string) => void
) => {
  if (
    targetID !== "Du må velge en knapp" &&
    targetID !== "" &&
    targetID !== "Knapp lagt til"
  ) {
    const tempTargetIDs = new Map(targetIDs);
    tempTargetIDs.set(targetID, targetIDName);
    setTargetIDs(tempTargetIDs);
    setTargetIDName("");
    setTargetID("Knapp lagt til");
  } else {
    setTargetID("Du må velge en knapp");
  }
};

const onClickHandler = (
  demoID: string,
  figmaLink: string,
  description: string,
  targetIDs: Map<string, string>,
  setNewIterationsLink: (newIterationsLink: string) => void,
  setOnboardingPage: (onboardingPage: string) => void
) => {
  addIteration({
    demo_id: demoID,
    figma_link: figmaLink,
    description: "",
    primary_action_ids: Array.from(targetIDs.keys())
  }).then((res) => setNewIterationsLink(res.iteration_link));
  setOnboardingPage("finish");
};
