import styles from "./IFrame.module.scss";

interface IFrameProps {
  link: string;
}

const IFrame = ({ link }: IFrameProps) => {
  const protoUrl = new URL(link);
  const urlParams = protoUrl.searchParams;

  // Make figma demo as realistic as possible
  urlParams.set("hide-ui", "1");
  urlParams.set("disable-default-keyboard-nav", "1");
  urlParams.set("hotspot-hints", "0");

  const url = protoUrl.toString();
  const iFrameUrl = `https://www.figma.com/embed?embed_host=share&url=${encodeURIComponent(
    url
  )}`;

  return (
    <iframe
      className={styles.IFrame}
      src={iFrameUrl}
      width="100%"
      height="100%"
      allowFullScreen></iframe>
  );
};

export default IFrame;
