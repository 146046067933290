import { useEffect, useState } from "react";
import { Click } from "../../../types/types";
import PersonsLogo from "../svg/PersonsLogo";
import styles from "./ButtonOverview.module.scss";

interface ButtonProps {
  clicks: Click[];
  numberOfsessions: number;
  primaryActionIDs?: string[];
}

const ButtonOverview = ({
  clicks,
  numberOfsessions,
  primaryActionIDs
}: ButtonProps) => {
  const [targetIDMapSort, setTargetIDMapSort] = useState(
    new Map<string, number>()
  );
  const [sessionsPerTargetIDMap, setSessionsPerTargetIDMap] = useState(
    new Map<string, number>()
  );

  useEffect(() => {
    if (clicks) {
      const targetIDMap = new Map<string, number>(); // targetID, clicks
      const targetIDSessionsMap = new Map<string, Set<string>>(); // targetID, Set<sessions>

      clicks.forEach((click: any) => {
        if (click.target_id != "" && click.target_id) {
          const targetID: string = click.target_id;
          const sessionID: string = click.session_id;
          targetIDMap.set(targetID, (targetIDMap.get(targetID) || 0) + 1); // +1 for each click saved
          targetIDSessionsMap.set(
            targetID,
            (targetIDSessionsMap.get(targetID) || new Set<string>()).add(
              sessionID
            ) // Add session to target ID set for each click
          );
        }
      }, []);

      // Sort by number of clicks.
      const targetIDMapSort = new Map(
        [...Array.from(targetIDMap.entries())].sort((a, b) => b[1] - a[1]) // TargetID, clicks (Sorted by number of clicks)
      );

      // Count number of sessions that have clicked on each target ID
      const sessionPerTargetIDMap = new Map<string, number>( // TargetID, number of sessions
        [...Array.from(targetIDSessionsMap.entries())].map(
          ([targetID, sessionSet]) => {
            return [targetID, sessionSet.size];
          }
        )
      );

      setSessionsPerTargetIDMap(sessionPerTargetIDMap);
      setTargetIDMapSort(targetIDMapSort);
    }
  }, [clicks]);

  const topTargetIDs = Array.from(targetIDMapSort.keys());
  const topTargetIDClicks = Array.from(targetIDMapSort.values());

  if (topTargetIDs.length > 0) {
    const visibleTopTargetIDs =
      topTargetIDs.length > 3 ? topTargetIDs.slice(0, 3) : topTargetIDs;
    const visibleTopTargetIDClicks =
      topTargetIDClicks.length > 3
        ? topTargetIDClicks.slice(0, 3)
        : topTargetIDClicks;

    return (
      <div className={styles.card}>
        <div className={styles.content}>
          <p className={styles.metric}>Engagement</p>
          <h3 className={styles.title}>Mest klikkede knapper</h3>
          <div className={styles.topTargetIDsContainer}>
            {visibleTopTargetIDs.map((targetID, index) => {
              const targetIDClicks = visibleTopTargetIDClicks[index];
              return (
                <div className={styles.targetIDContainer} key={index}>
                  <div
                    className={
                      primaryActionIDs
                        ? primaryActionIDs.includes(targetID)
                          ? styles.targetIDInfoContainer +
                            " " +
                            styles.primaryAction
                          : styles.targetIDInfoContainer
                        : styles.targetIDInfoContainer
                    }>{`Target ID: ${targetID}`}</div>
                  <div className={styles.targetIDNumbersContainer}>
                    <div>{targetIDClicks}</div>
                    <div style={{ fontSize: "0.75rem" }}>{"klikk totalt"}</div>
                    <div style={{ fontSize: "0.9rem" }}>
                      {`${Math.round(
                        (sessionsPerTargetIDMap.get(targetID) /
                          numberOfsessions) *
                          100
                      )}% `}
                      <PersonsLogo />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.card}>
        <div className={styles.content}>
          <p className={styles.metric}>Engagement</p>
          <h3 className={styles.title}>Mest klikkede knapper</h3>
          <div className={styles.metric}>Ingen knapper er trykket på.</div>
        </div>
      </div>
    );
  }
};

export default ButtonOverview;
