import Button from "./Button";
import NavBar from "../../../components/NavBar";
import ContinueArrow from "../svg/continueArrow";
import styles from "./NamePage.module.scss";
import { useState } from "react";

interface NamePageProps {
  demoName: string;
  setDemoName: (demoName: string) => void;
  buttons: Map<string, boolean>;
  setButtons: (buttons: Map<string, boolean>) => void;
  setOnboardingPage: (onboardingPage: string) => void;
  figmaLink: string;
  setFigmaLink: (figmaLink: string) => void;
}

const NamePage = ({
  demoName,
  setDemoName,
  buttons,
  setButtons,
  setOnboardingPage,
  figmaLink,
  setFigmaLink
}: NamePageProps) => {
  const [changeName, setChangeName] = useState(false);
  const [changeFigmaLink, setChangeFigmaLink] = useState(false);
  return (
    <div className={styles.wrapper}>
      <NavBar />
      <div className={styles.container}>
        <div className={styles.demoNameContainer}>
          <div className={styles.demoNameTitle}>
            Navn på demo
            <div
              className={styles.changeButton}
              onClick={() => setChangeName(true)}>
              Endre
            </div>
          </div>
          <div className={styles.demoName}>
            {changeName || demoName === "" ? (
              <input
                className={styles.input}
                value={demoName}
                onChange={(e) => setDemoName(e.target.value)}
                autoFocus
                onBlur={() => setChangeName(false)}
                placeholder={
                  demoName === "" ? "Legg til demonavn" : ""
                }></input>
            ) : (
              <div onClick={() => setChangeName(true)}>{demoName}</div>
            )}
          </div>
        </div>
        <div className={styles.demoNameContainer}>
          <div className={styles.demoNameTitle}>
            Figma-link
            <div
              className={styles.changeButton}
              onClick={() => setChangeFigmaLink(true)}>
              Endre
            </div>
          </div>
          <div className={styles.demoName}>
            {changeFigmaLink || figmaLink === "" ? (
              <input
                className={styles.input}
                value={figmaLink}
                onChange={(e) => setFigmaLink(e.target.value)}
                autoFocus
                onBlur={() => setChangeFigmaLink(false)}
                placeholder={
                  figmaLink === "" ? "Legg til Figma-link" : ""
                }></input>
            ) : (
              <div onClick={() => setChangeFigmaLink(true)}>{figmaLink}</div>
            )}
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.infoTitle}>Hva vil du måle? </div>
          <div className={styles.infoText}>
            <p>
              Med demo har du mulighet til å undersøke hva brukerne faktisk gjør
              med skissene du gir dem. Du får innsikt i hvordan de oppfører seg
              slik at du kan tilpasse deg markedet ditt bedre.{" "}
            </p>
            <p>
              For å kunne forbedre produktet ditt trenger vi en målestokk. Denne
              skreddersyr vi ut fra hva du ønsker å oppnå med din demo.
            </p>
          </div>
        </div>
        <div className={styles.demoGoal}>
          <div className={styles.buttonTitle}>Velg ett eller flere mål:</div>
          <div className={styles.buttonContainer}>
            {Array.from(buttons.keys()).map((button: string, index) => (
              <div key={index}>
                <Button
                  text={button}
                  onClick={() => setButton(button, buttons, setButtons)}
                  isClicked={buttons.get(button)}
                />
              </div>
            ))}
          </div>
        </div>
        <div className={styles.continueButtonContainer}>
          <div
            className={styles.continueButton}
            onClick={() => setOnboardingPage("choose button")}>
            Videre <ContinueArrow />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NamePage;

const setButton = (
  buttonName: string,
  buttons: Map<string, boolean>,
  setButtons: (buttons: Map<string, boolean>) => void
) => {
  const tempButtons = new Map(buttons);
  tempButtons.set(buttonName, !tempButtons.get(buttonName));
  setButtons(tempButtons);
};
