const getBackendURL = (host: string) => {
  if (host.endsWith("localhost")) {
    return "http://localhost:8080/";
  } else if (host.endsWith("iterate-demo-summer-project.test.iterate.no")) {
    return "https://api.iterate-demo-summer-project.test.iterate.no/";
  } else {
    // default to production
    return "https://api.iterate-demo-summer-project.app.iterate.no/";
  }
};

const getFrontendUrl = (host: string) => {
  if (host.endsWith("localhost")) {
    return "http://localhost:3000/";
  } else if (host.endsWith("iterate-demo-summer-project.test.iterate.no")) {
    return "https://frontend.iterate-demo-summer-project.test.iterate.no/";
  } else {
    // default to production
    return "https://frontend.iterate-demo-summer-project.app.iterate.no/";
  }
};

export const backendURL = getBackendURL(window.location.hostname);
export const frontendURL = getFrontendUrl(window.location.hostname);
