const Two = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="#FEFEFE" />
      <path
        d="M18.96 20V18.72H12.624L15.072 16.544C15.728 15.968 16.224 15.504 16.592 15.136C16.96 14.784 17.312 14.384 17.648 13.952C17.984 13.52 18.224 13.104 18.368 12.704C18.512 12.304 18.576 11.856 18.576 11.392C18.576 10.32 18.224 9.488 17.52 8.896C16.816 8.304 15.92 8 14.816 8C13.616 8 12.624 8.336 11.84 9.024C11.056 9.712 10.656 10.688 10.656 11.968H12.096C12.144 11.12 12.4 10.48 12.896 10.016C13.392 9.552 14 9.328 14.752 9.328C15.44 9.328 16 9.52 16.432 9.888C16.864 10.256 17.072 10.784 17.072 11.472C17.072 12.224 16.736 13.008 16.08 13.792C15.728 14.224 14.944 14.976 13.728 16.048L10.736 18.72V20H18.96Z"
        fill="#121212"
      />
    </svg>
  );
};

export default Two;
