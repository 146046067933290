import IFrame from "./IFrame";
import styles from "./Demo.module.scss";

import { addSession, getIterationFromLink } from "../../services/demo";
import { useState, useEffect } from "react";
import { addClick } from "../../services/clicks";

import LoadingAnimation from "../../assets/images/demo-loading-screen.gif";

interface DemoProps {
  iterationLink: string;
}

const Demo = ({ iterationLink }: DemoProps) => {
  const [link, setLink] = useState("");
  const [demoID, setDemoID] = useState("");
  const [sessionID, setSessionID] = useState("");
  const [targetID, setTargetID] = useState("");
  const [iterationID, setIterationID] = useState("");
  const [presentedNodeChanged, setPresentedNodeChanged] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false);

  useEffect(() => {
    getIterationFromLink(iterationLink).then((res) => {
      setDemoID(res.demo_id);
      setIterationID(res.id);
      setLink(res.figma_link);
    });
  }, []);

  useEffect(() => {
    async function eventHandler(event: any) {
      if (event.origin !== "https://www.figma.com") return;

      // On initial load, create the session in backend and get the session ID
      if (event.data.type === "INITIAL_LOAD") {
        const data = await addSession(iterationID);
        setSessionID(data.id);
        setInitialLoad(true);
      }

      if (event.data.type === "MOUSE_PRESS_OR_RELEASE") {
        setTargetID(event.data.data.targetNodeId);
      }

      if (event.data.type === "PRESENTED_NODE_CHANGED") {
        setPresentedNodeChanged(true);
      }
    }

    window.addEventListener("message", eventHandler);

    return () => {
      window.removeEventListener("message", eventHandler);
    };
  }, [iterationID]);

  useEffect(() => {
    if (presentedNodeChanged) {
      addClick(targetID, sessionID);
      setPresentedNodeChanged(false);
    }
  }, [presentedNodeChanged]);

  if (!link) {
    return (
      <div className={styles.loadingScreen}>
        <img
          className={styles.loadingAnimation}
          src={LoadingAnimation}
          alt="En animasjon som viser at demoen din laster inn"
        />
      </div>
    );
  }
  return (
    <>
      <div
        className={
          initialLoad ? styles.IFrameContainer : styles.IFrameContainerHidden
        }>
        <IFrame link={link}></IFrame>
      </div>
      <div
        className={
          initialLoad ? styles.IFrameContainerHidden : styles.IFrameContainer
        }>
        <img
          className={styles.loadingAnimation}
          src={LoadingAnimation}
          alt="En animasjon som viser at demoen din laster inn"
        />
      </div>
    </>
  );
};

export default Demo;
