const PositiveArrow = () => {
  return (
    <svg
      width="9"
      height="11"
      viewBox="0 0 9 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.60547 9.73239L4.60547 1"
        stroke="#00CC99"
        strokeWidth="1.45029"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 4.6385L4.6036 1L8.20721 4.6385"
        stroke="#00CC99"
        strokeWidth="1.45029"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PositiveArrow;
