import { act } from "react-dom/test-utils";
import { backendURL } from "./url";

// getActiveSessions returns all active sessions.
export const getActiveSessions = async (iterationID: string) => {
  try {
    const response = await fetch(
      `${backendURL}v1/iterations/${iterationID}/sessions?take=999999`,
      {
        method: "GET"
      }
    );
    const data = await response.json();

    const now = new Date();
    const activeSessions = data.filter(
      (session: any) =>
        new Date(session.started_at) > new Date(now.getTime() - 300000)
    );
    return activeSessions.length;
  } catch (error) {
    console.log(error);
  }
};
