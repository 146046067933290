const PersonsLogo = () => {
  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.4865 10.341C10.6658 8.9272 9.35215 7.86607 7.79736 7.36106C8.54261 6.9179 9.12174 6.24231 9.4458 5.43809C9.76986 4.63386 9.82091 3.74549 9.59111 2.90943C9.36131 2.07338 8.86337 1.3359 8.17379 0.810288C7.48421 0.284673 6.64113 0 5.77408 0C4.90702 0 4.06394 0.284673 3.37436 0.810288C2.68478 1.3359 2.18684 2.07338 1.95704 2.90943C1.72724 3.74549 1.77829 4.63386 2.10235 5.43809C2.42641 6.24231 3.00554 6.9179 3.75079 7.36106C2.196 7.86607 0.882318 8.9272 0.0616049 10.341C0.0213105 10.4073 0 10.4834 0 10.5609C0 10.6385 0.0213105 10.7146 0.0616049 10.7808C0.0991989 10.8483 0.154285 10.9043 0.221053 10.943C0.287822 10.9817 0.363798 11.0016 0.44097 11.0008H11.1072C11.1844 11.0016 11.2603 10.9817 11.3271 10.943C11.3939 10.9043 11.449 10.8483 11.4865 10.7808C11.5268 10.7146 11.5482 10.6385 11.5482 10.5609C11.5482 10.4834 11.5268 10.4073 11.4865 10.341Z"
        fill="#E4E7EC"
      />
    </svg>
  );
};

export default PersonsLogo;
