import styles from "./GraphComponent.module.scss";

import { scaleTime } from "d3-scale";

import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from "recharts";
import PositiveArrow from "../svg/PositiveArrow";
import NegativeArrow from "../svg/NegativeArrow";
import { Iteration, Session } from "../../../types/types";

interface GraphComponentProps {
  sessions?: Session[];
  iterations?: Iteration[];
}

const GraphComponent = ({ sessions, iterations }: GraphComponentProps) => {
  let accSessions: { date: string; numberOfUsers: number }[] = [];

  if (sessions) {
    if (sessions.length > 0) {
      const longerThanOneDay =
        new Date().getTime() - new Date(sessions[0].started_at).getTime() >
        86400000;

      const scale = scaleTime()
        .domain([new Date(sessions[0].started_at), new Date()])
        .nice();
      const ticks = scale.ticks(5);

      accSessions = ticks.map((tick: Date) => {
        return {
          date: longerThanOneDay
            ? tick.toLocaleString()
            : tick.toLocaleTimeString(),
          numberOfUsers: 0
        };
      });

      // Find timstamp closest to start time of each session
      sessions.map((session) => {
        const time = new Date(session.started_at);
        ticks.sort(function (a, b) {
          const distancea = Math.abs(time.getTime() - a.getTime());
          const distanceb = Math.abs(time.getTime() - b.getTime());
          return distancea - distanceb;
        });

        const closestTick = longerThanOneDay
          ? ticks[0].toLocaleString()
          : ticks[0].toLocaleTimeString();

        accSessions.map((accSession) => {
          if (accSession.date === closestTick) {
            accSession.numberOfUsers += 1;
          }
        });
      });
    } else {
      accSessions = accSessions;
    }

    accSessions.map((accSession, index) => {
      accSession.date = accSession.date.slice(0, 5);
      if (index !== 0) {
        accSession.numberOfUsers =
          accSession.numberOfUsers + accSessions[index - 1].numberOfUsers;
      }
    });

    return (
      <div className={styles.card}>
        <div className={styles.content}>
          <p className={styles.metric}>Acquisition og Activation</p>
          <h3 className={styles.title}>Besøk på demo over tid</h3>
          <div className={styles.graph}>
            <LineChart width={575} height={208} data={accSessions}>
              <XAxis dataKey="date" />
              <YAxis dataKey="numberOfUsers" />
              <Tooltip itemStyle={{ color: "black" }} />
              <Line
                name="Antall brukere"
                type="monotone"
                dataKey="numberOfUsers"
                stroke="#FEF0C7"
                isAnimationActive={false}
              />
              <Legend />
            </LineChart>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.card}>
        <div className={styles.content}>
          <p className={styles.metric}>Acquisition og Activation</p>
          <h3 className={styles.title}>Besøk på demo over tid</h3>
          <div className={styles.title}>Ingen data tilgjengelig</div>
        </div>
      </div>
    );
  }
};

export default GraphComponent;

const progressionPercent = (iterations: Iteration[]) => {
  if (iterations.length > 1) {
    if (iterations[iterations.length - 1] > iterations[iterations.length - 2]) {
      return styles.positivePercent;
    }

    if (iterations[iterations.length - 1] < iterations[iterations.length - 2]) {
      return styles.negativePercent;
    }

    if (
      iterations[iterations.length - 1] === iterations[iterations.length - 2]
    ) {
      return styles.neutralPercent;
    }
  } else {
    return styles.neutralPercent;
  }
};

const progressionArrows = (iterations: Iteration[]) => {
  if (iterations[iterations.length - 1] > iterations[iterations.length - 2]) {
    return <PositiveArrow />;
  }

  if (iterations[iterations.length - 1] < iterations[iterations.length - 2]) {
    return <NegativeArrow />;
  }
};
