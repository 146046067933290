const ContinueArrow = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.36353 8.5H14.409"
        stroke="white"
        stroke-width="1.31417"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.89038 2.97656L14.409 8.49929L9.89038 14.022"
        stroke="white"
        stroke-width="1.31417"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ContinueArrow;
