import NavBar from "../../../components/NavBar";
import styles from "./FinishPage.module.scss";
import { frontendURL } from "../../../services/url";
import { useNavigate } from "react-router-dom";
import ContinueArrow from "../svg/continueArrow";

interface FinishPageProps {
  iterationLink: string;
}

const FinishPage = ({ iterationLink }: FinishPageProps) => {
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <NavBar />
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Demoen din er klar!</div>
          <div className={styles.link}>
            {`Delbar link: ${frontendURL}demo/?iterationLink=${iterationLink}`}
          </div>
          <div
            className={styles.toStatsButton}
            onClick={() => navigate(`/stats/?iterationLink=${iterationLink}`)}>
            Gå til stats-siden <ContinueArrow />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinishPage;
