import styles from "./TextInput.module.scss";

interface TextInputProps {
  placeholder?: string;
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

const TextInput = ({ placeholder, label, onChange, value }: TextInputProps) => {
  return (
    <div>
      <label htmlFor="name" className={styles.label}>
        {label}
      </label>
      <input
        type="text"
        id="name"
        name="name"
        onChange={onChange}
        placeholder={placeholder}
        className={styles.textInput}
        value={value}
      />
    </div>
  );
};

export default TextInput;
