import styles from "./Button.module.scss";

interface ButtonProps {
  text: string;
  onClick: () => void;
  isClicked?: boolean;
  addButton?: boolean;
}

const Button = ({
  text,
  onClick,
  isClicked,
  addButton = false
}: ButtonProps) => {
  if (addButton) {
    return (
      <div>
        <button className={styles.addButton} onClick={onClick}>
          {text}
        </button>
      </div>
    );
  } else {
    return (
      <div>
        {isClicked ? (
          <button className={styles.clickedButton} onClick={onClick}>
            {text}
          </button>
        ) : (
          <button className={styles.button} onClick={onClick}>
            {text}
          </button>
        )}
      </div>
    );
  }
};

export default Button;
