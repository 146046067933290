const One = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15Z"
        fill="#FEFEFE"
      />
      <path
        d="M18.304 18.72H15.584V8.208H14.272C13.44 9.024 12.448 9.552 11.28 9.776V11.104C12.464 10.928 13.424 10.528 14.16 9.904V18.72H11.44V20H18.304V18.72Z"
        fill="#121212"
      />
    </svg>
  );
};

export default One;
