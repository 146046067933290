import styles from "./NavBar.module.scss";

const NavBar = () => {
  return (
    <nav className={styles.navBar}>
      <svg
        width="106"
        height="43"
        viewBox="0 0 106 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 4.29426C0 1.92261 1.92261 0 4.29426 0L101.589 0C103.96 0 105.883 1.9226 105.883 4.29426V38.4414C105.883 40.813 103.96 42.7356 101.589 42.7356H4.29426C1.92261 42.7356 0 40.813 0 38.4414L0 4.29426Z"
          fill="#FF99CC"
        />
        <path
          d="M12.028 29.8217V11.3575H16.3837C19.1928 11.3575 21.339 12.2412 22.8856 13.9772C24.4322 15.7131 25.1897 17.8594 25.1897 20.416C25.1897 23.0041 24.4006 25.2135 22.8541 27.0442C21.3075 28.9064 19.1297 29.8217 16.3206 29.8217H12.028ZM9.12424 8.7693V32.4414H16.3521C18.2775 32.4414 20.0134 32.0942 21.56 31.3998C23.1066 30.7055 24.3375 29.7901 25.2844 28.6223C26.2313 27.4545 26.9572 26.1604 27.4622 24.7401C27.9672 23.3198 28.2197 21.8047 28.2197 20.2582C28.2197 18.175 27.8094 16.2813 26.9572 14.5769C26.105 12.8725 24.811 11.4837 23.0119 10.379C21.2128 9.30587 19.035 8.7693 16.5415 8.7693H9.12424ZM30.91 32.4414H46.7545V29.8217H33.8138V21.7732H45.5551V19.1535H33.8138V11.3575H46.1232V8.7693H30.91V32.4414ZM71.8139 32.4414V8.7693H67.6792L60.4513 27.5807L53.2865 8.7693H49.1202V32.4414H51.8978V13.125L59.315 32.4414H61.5244L68.9732 13.125V32.4414H71.8139ZM85.9752 11.0103C88.4687 11.0103 90.4887 11.894 92.0668 13.6931C93.645 15.4922 94.4341 17.7963 94.4341 20.6054C94.4341 23.446 93.6765 25.7501 92.1931 27.5176C90.6781 29.3167 88.6581 30.2004 86.1015 30.2004C83.5449 30.2004 81.4617 29.3167 79.8836 27.5176C78.2739 25.7185 77.4848 23.4144 77.4848 20.6054C77.4848 17.7331 78.2423 15.4291 79.7574 13.6615C81.2724 11.894 83.3555 11.0103 85.9752 11.0103ZM86.0384 33.0411C89.384 33.0411 92.13 31.8417 94.2447 29.4745C96.3594 27.1389 97.4325 24.172 97.4325 20.6054C97.4325 17.0388 96.3594 14.1034 94.2447 11.7362C92.0984 9.369 89.3524 8.20118 86.0384 8.20118C82.598 8.20118 79.8205 9.369 77.7058 11.7046C75.5595 14.0719 74.4864 17.0388 74.4864 20.6054C74.4864 24.172 75.5595 27.1389 77.7058 29.5061C79.852 31.8733 82.6296 33.0411 86.0384 33.0411Z"
          fill="black"
        />
      </svg>
    </nav>
  );
};

export default NavBar;
